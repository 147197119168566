import { DataClient } from "@/services/DataClient";
import { defineStore } from "pinia";


export const useLocationStore = defineStore("locationStore", {
    state: () => {
        return {
            resolved: false,
            placeId: "",
            nearestMeetingPointId: "",
            name: "",
            address: "",
            longitude: 0,
            latitude: 0,
            type: "" as "" | "hotel" | "address"
        };
    },
    actions: {
        async resolve(placeId: string) {
            //window.alert("Resolving location " + placeId);
            console.log("Resolving location", placeId);

            const r = await DataClient.instance.getAutocompletionDetails(placeId);

            console.log("Resolved location", r);

            this.placeId = placeId;
            this.name = r.name;
            this.address = r.address;
            this.longitude = r.longitude;
            this.latitude = r.latitude;
            this.resolved = true;
        },

        clear() {
            this.resolved = false;
            this.placeId = "";
            this.name = "";
            this.address = "";
            this.longitude = 0;
            this.latitude = 0;
        },

        initialize() {
            // TODO: Load from local storage
            console.log("Initializing location store");
        }
    }
});

export interface GenericResponse {
    success: boolean;
    httpStatusCode?: number;
    error?: string;
}

export class ApiError {
    public readonly statusCode: number;
    public readonly errorType: string;
    public readonly errorMessage: string;

    constructor(payload: any) {
        this.statusCode = payload.statusCode || 500;
        this.errorType = payload.errorType || "UnknownError";
        this.errorMessage = payload.errorMessage || "Unknown error message";
    }
}

export abstract class BaseService {

    protected readonly baseUrl: string;

    public constructor() {
        this.baseUrl = "/api";
    }

    public async httpGet<T>(url: string | string[], params?: { [key: string]: string }): Promise<T> {
        const targetUrl = this.buildTargetUrl(url);
        let query = "";

        if (params) {
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        }

        const response = await fetch(query ? `${targetUrl}?${query}` : targetUrl);
        const json = await response.json();

        if (response.ok) {
            return json;
        } else {
            throw new ApiError(json);
        }
    }

    public async httpPost<T = void>(url: string | string[], payload: any): Promise<T> {
        const response = await fetch(
            this.buildTargetUrl(url),
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            });

        const json = await response.json();

        if (response.ok) {
            return json;
        } else {
            throw new ApiError(json);
        }
    }

    public async httpPostAsBlob<T = void>(url: string | string[], payload: any): Promise<Blob> {
        const response = await fetch(
            this.buildTargetUrl(url),
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            });

        const blob = await response.blob();

        if (response.ok) {
            return blob;
        } else {
            throw new ApiError({});
        }
    }

    public async httpDelete<T = void>(url: string | string[]): Promise<T> {
        const response = await fetch(this.buildTargetUrl(url), { method: "DELETE" });
        const json = await response.json();

        if (response.ok) {
            return json;
        } else {
            throw new ApiError(json);
        }
    }

    protected buildTargetUrl(url: string | string[]): string {
        const pathPart = Array.isArray(url)
            ? url.join("/")
            : url;

        return `${this.baseUrl}/${pathPart}`;
    }

    protected buildHeaders(): { Authorization: string } | undefined {
        return undefined;
    }

}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { i18n, loadLanguageAsync } from "@/i18n";

import { DataClient } from "@/services/DataClient";
import { Component } from "vue";
import { Utilities } from "@/services/Utilities";

Vue.use(VueRouter);

// const supportedLocales = ["es", "en", "it", "fr", "de"];
// const defaultLocale = "en";

// function getFilteredUserLocale() {
//     const userLocale = navigator.language || (navigator as any).userLanguage || defaultLocale;
//     const userLocaleShort = userLocale.split("-")[0];

//     return supportedLocales.includes(userLocaleShort) ? userLocaleShort : defaultLocale;
// }

interface TranslatableRoute {
    name: string;
    paths: {
        [key: string]: string;
    };
    component: Component | (() => Promise<any>);
    meta?: any;
}

const translatableRoutes: Array<TranslatableRoute> = [
    {
        name: "home",
        paths: {
            es: "/inicio",
            en: "/home",
            fr: "/accueil",
            de: "/startseite",
            it: "/inizio",
        },
        component: () => import(/* webpackChunkName: "home" */ "../views/HomePageView.vue")
    },
    {
        name: "activity",
        paths: {
            es: "/actividad/:id",
            en: "/activity/:id",
            fr: "/activite/:id",
            de: "/aktivitat/:id",
            it: "/attivita/:id",
        },
        meta: {
            divider: true,
        },
        component: () => import(/* webpackChunkName: "activities" */ "../views/ActivityPageView.vue"),
    },
];

export function initializeRouter(): { router: VueRouter, initialLocale: string } {
    const supportedLocales = DataClient.instance.supportedLocales;
    const defaultLocale = DataClient.instance.defaultLocale;

    const routes: Array<RouteConfig> = [
        {
            path: "/",
            redirect: () => {
                const supportedLocales = DataClient.instance.supportedLocales;
                const defaultLocale = DataClient.instance.defaultLocale;

                const targetLocale = Utilities.getBrowserLanguage();
                return supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;
            }
        }
    ];

    supportedLocales.forEach((locale) => {
        routes.push({
            path: `/${locale}`,
            redirect: { name: `home.${locale}` }
        });
    });

    translatableRoutes.forEach((route) => {
        supportedLocales.forEach((locale) => {
            const path = route.paths[locale];

            if (path) {
                routes.push({
                    name: `${route.name}.${locale}`,
                    path: `/${locale}${path}`,
                    component: route.component,
                    meta: route.meta
                });
            }
        });
    });

    const router = new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes,
        scrollBehavior: (to, from, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        }
    });

    router.beforeEach(async (to, from, next) => {
        const targetLocale = ((to.path || "").split("/")[1] || "").toLowerCase();
        const locale = supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;

        if (locale !== i18n.locale) {
            await loadLanguageAsync(locale);
        }

        await DataClient.instance.loadConfiguration(locale);

        next();
    });

    const targetLocale = window.location.pathname.split("/")[1] || Utilities.getBrowserLanguage();
    const initialLocale = supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;

    return { router, initialLocale };
}

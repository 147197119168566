import Vue from "vue";
import VueI18n from "vue-i18n";
import * as messages from "@/locales/es.json";

Vue.use(VueI18n);

const defaultLanguage = "es";

export const i18n = new VueI18n({
    locale: defaultLanguage,
    fallbackLocale: defaultLanguage,
})

i18n.setLocaleMessage(defaultLanguage, messages);

const loadedLanguages = [defaultLanguage]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
    i18n.locale = lang
    document.querySelector("html")?.setAttribute("lang", lang)
    return lang
}

export function loadLanguageAsync(lang: string) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "locale-[request]" */ `@/locales/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}

import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import Vue from "vue";


export class BaseView extends Vue {

    /*
        color = "#052e5a";
        color2 = "red darken-4";

    */

    get isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
    }

    get primaryColor() {
        return "#031c36";
        // return DataClient.instance.primaryColor;
    }

    get secondaryColor() {
        return DataClient.instance.secondaryColor;
    }

    buildNavigationRoute(name: string, params?: any, query?: any) {
        return { name: name + "." + this.$i18n.locale, params, query };
    }

    formatPrice(value: number): string {
        return Utilities.formatPrice(value);
    }

    formatDate(date: string) {
        const formattedDate = Utilities.formatDateToDDMMYYYY(date);
        const dayOfWeek = Utilities.getDayOfWeekNameYYYYMMDD(date);

        const translatedDayOfWeek = this.$t("days." + dayOfWeek.toLowerCase()) as string;

        return `${translatedDayOfWeek}, ${formattedDate}`;
    }

    formatDuration(duration: number) {
        const hours = Math.floor(duration);
        const minutes = Math.floor((duration - hours) * 60);

        const parts: string[] = [];
        if (hours > 0) {
            parts.push(hours + " " + this.$t("global.hours"));
        }

        if (minutes > 0) {
            parts.push(minutes + " " + this.$t("global.minutes"));
        }

        return parts.join(` ${this.$t("global.and")} `);
    }

    metaInfo() {
        return {
            link: [
                {
                    rel: "canonical",
                    href: window.location.href,
                },
            ],
        };
    }

    getAffiliateId() {
        // get from local storage the affiliate id and check if its from 24 hours before
        // if not, return an empty string
        // if yes, return the affiliate id

        const affiliateId = localStorage.getItem("affiliate");
        const affiliateDate = localStorage.getItem("affiliateDate");

        if (affiliateId && affiliateDate) {
            const date = new Date(affiliateDate);
            const now = new Date();

            if (now.getTime() - date.getTime() < 24 * 60 * 60 * 1000) {
                return affiliateId;
            }
        }

        return "";
    }

    async sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }


}


import Vue from "vue";


const elements = new Set<HTMLElement>();

function elementIsVisibleInViewport(el: HTMLElement, buffer = 100) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top + buffer;
    const elemBottom = rect.bottom;
    //console.log(el, rect, elemTop, elemBottom, window.innerHeight);
    return elemTop < window.innerHeight && elemBottom >= 0
}

function checkVisibility() {
    elements.forEach(el => {
        if (elementIsVisibleInViewport(el)) {
            // el.classList.remove("fade-in");
            el.style.opacity = '1'
            el.style.transform = 'scale(1)'

            //console.log("FadeIn removed", el);
            elements.delete(el);
        }
    });

    if (elements.size > 0) {
        window.addEventListener("scroll", checkVisibility);
    } else {
        window.removeEventListener("scroll", checkVisibility);
    }
}


Vue.directive("fade", {
    inserted: function (el: HTMLElement, binding) {
        //get the class we gave to the directive,  {animation :'classname'}
        const animationClass = "fade-in";

        //console.log("FadeIn inserted", el);
        el.classList.add(animationClass);
        elements.add(el);

        setTimeout(checkVisibility, 200);
    }
});
